import { createStore, useStore as baseUseStore, } from 'vuex';
export const key = Symbol();
const state = {
    lang: "de",
};
export var MUTATIONS;
(function (MUTATIONS) {
    MUTATIONS["EDIT_LANG"] = "EDIT_LANG";
    MUTATIONS["initialiseStore"] = "initialiseStore";
})(MUTATIONS || (MUTATIONS = {}));
const mutations = {
    [MUTATIONS.EDIT_LANG](state, lang) {
        state.lang = lang;
    },
    [MUTATIONS.initialiseStore](state) {
    },
};
export const store = createStore({ state, mutations });
export function useStore() {
    return baseUseStore(key);
}
