import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    key: 0,
    id: "desknavCon"
};
const _hoisted_2 = _createElementVNode("div", {
    id: "deskNavGes",
    class: "fontDbT4Fett"
}, "Geschäftskunden", -1);
const _hoisted_3 = _createElementVNode("div", {
    id: "desknavPri",
    class: "fontDbT4"
}, [
    _createElementVNode("a", { href: "https://www.bahn.de/" }, "Privatkunden")
], -1);
const _hoisted_4 = [
    _hoisted_2,
    _hoisted_3
];
const _hoisted_5 = { style: { "display": "flex", "justify-content": "space-between", "margin-right": "16px" } };
const _hoisted_6 = ["href"];
const _hoisted_7 = ["alt"];
const _hoisted_8 = _createElementVNode("div", null, [
    _createElementVNode("img", {
        src: "assets/logo_bahnbusiness.jpg",
        style: { "margin": "24px 80px 0 22px", "height": "20px" }
    })
], -1);
const _hoisted_9 = { class: "deskHeadMenu" };
const _hoisted_10 = { style: { "display": "inline-block" } };
const _hoisted_11 = { class: "metanav__item" };
const _hoisted_12 = ["href"];
const _hoisted_13 = { class: "fontDbT5" };
const _hoisted_14 = {
    key: 0,
    class: "metanav__item"
};
const _hoisted_15 = ["href"];
const _hoisted_16 = { class: "fontDbT5" };
const _hoisted_17 = { class: "metanav__item" };
const _hoisted_18 = ["href"];
const _hoisted_19 = { class: "fontDbT5" };
const _hoisted_20 = _createElementVNode("div", { id: "langPopOverDiv" }, [
    _createElementVNode("ul", { id: "langPopOverUl" })
], -1);
const _hoisted_21 = ["action"];
const _hoisted_22 = ["value"];
const _hoisted_23 = ["value"];
const _hoisted_24 = _createElementVNode("button", {
    type: "submit",
    class: "search-form__search-icon"
}, null, -1);
const _hoisted_25 = {
    key: 2,
    class: "max_screen",
    id: "nav_links"
};
const _hoisted_26 = { style: { "height": "54px", "box-size": "border-box", "width": "100%", "display": "inline-block", "background": "#ec0016", "border-left": "solid #ec0016 4px" } };
const _hoisted_27 = { style: { "background": "#ec0016", "height": "53px" } };
const _hoisted_28 = ["href"];
const _hoisted_29 = ["href"];
const _hoisted_30 = ["href"];
const _hoisted_31 = { key: 0 };
const _hoisted_32 = { class: "desk_men" };
const _hoisted_33 = {
    key: 0,
    class: "ul_1"
};
const _hoisted_34 = ["href"];
const _hoisted_35 = ["href"];
const _hoisted_36 = ["href"];
const _hoisted_37 = {
    key: 1,
    class: "ul_1_cluster"
};
const _hoisted_38 = ["href"];
const _hoisted_39 = ["href"];
const _hoisted_40 = { key: 2 };
const _hoisted_41 = {
    key: 0,
    class: "deskMenuTipp",
    style: { "padding": "24px 15px 0 24px" }
};
const _hoisted_42 = {
    key: 0,
    style: { "width": "calc(75% - 10px)" }
};
const _hoisted_43 = { class: "nav__link" };
const _hoisted_44 = {
    key: 1,
    style: { "width": "25%" }
};
const _hoisted_45 = { class: "nav__link" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ion_popover = _resolveComponent("ion-popover");
    const _component_ion_input = _resolveComponent("ion-input");
    const _component_ion_toolbar = _resolveComponent("ion-toolbar");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        false
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _hoisted_4))
            : _createCommentVNode("", true),
        (_ctx.$windowWidth > 990)
            ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                key: 1,
                onMouseenter: _cache[2] || (_cache[2] = ($event) => (_ctx.setMenuContentOff())),
                id: "bahn_header1_desk"
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", null, [
                            _createElementVNode("a", {
                                href: _ctx.headerTranslation[_ctx.store.state.lang].startseite.link,
                                style: { "padding": "8px 0px 20px 20px", "display": "block" },
                                class: "head__logo js-nav-logo"
                            }, [
                                _createElementVNode("img", {
                                    class: "head__logo-image",
                                    style: { "height": "32px", "margin-top": "10px" },
                                    alt: _ctx.headerTranslation[_ctx.store.state.lang].startseite.title,
                                    src: "assets/db-logo.svg"
                                }, null, 8, _hoisted_7)
                            ], 8, _hoisted_6)
                        ]),
                        _hoisted_8,
                        _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("ul", _hoisted_10, [
                                _createElementVNode("li", _hoisted_11, [
                                    _createElementVNode("a", {
                                        href: _ctx.headerTranslation[_ctx.store.state.lang].startseite.link,
                                        class: "metanav__link"
                                    }, [
                                        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.headerTranslation[_ctx.store.state.lang].startseite.title), 1)
                                    ], 8, _hoisted_12)
                                ]),
                                (_ctx.store.state.lang == 'de')
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                                        _createElementVNode("a", {
                                            style: { "border-left": "1px solid #333" },
                                            href: _ctx.headerTranslation[_ctx.store.state.lang].bahnDe.link,
                                            class: "metanav__link"
                                        }, [
                                            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.headerTranslation[_ctx.store.state.lang].bahnDe.title), 1)
                                        ], 8, _hoisted_15)
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("li", _hoisted_17, [
                                    _createElementVNode("a", {
                                        style: { "border-left": "1px solid #333", "border-right": "1px solid #333" },
                                        href: _ctx.headerTranslation[_ctx.store.state.lang].hilfeKontakt.link,
                                        class: "metanav__link"
                                    }, [
                                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.headerTranslation[_ctx.store.state.lang].hilfeKontakt.title), 1)
                                    ], 8, _hoisted_18)
                                ])
                            ]),
                            false
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.setOpenLang(true, $event))),
                                    class: _normalizeClass(_ctx.langSelectValueCSS)
                                }, _toDisplayString(_ctx.langValues[_ctx.store.state.lang]), 3))
                                : (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    class: _normalizeClass(_ctx.langSelectValueCSS)
                                }, _toDisplayString(_ctx.langValues[_ctx.store.state.lang]), 3)),
                            false
                                ? (_openBlock(), _createBlock(_component_ion_popover, {
                                    key: 2,
                                    showBackdrop: false,
                                    "is-open": _ctx.isOpenRefLang,
                                    "css-class": "langPopPoverCont",
                                    event: _ctx.eventLang,
                                    translucent: true,
                                    onDidDismiss: _cache[1] || (_cache[1] = ($event) => (_ctx.setOpenLang(false)))
                                }, {
                                    default: _withCtx(() => [
                                        _hoisted_20
                                    ]),
                                    _: 1
                                }, 8, ["is-open", "event"]))
                                : _createCommentVNode("", true),
                            _createElementVNode("form", {
                                action: _ctx.headerTranslation[_ctx.store.state.lang].actionLink,
                                autocomplete: "off",
                                method: "get",
                                "accept-charset": "UTF-8",
                                class: "search__form-compact fontDbT5"
                            }, [
                                _createElementVNode("input", {
                                    type: "hidden",
                                    name: "s",
                                    value: _ctx.headerTranslation[_ctx.store.state.lang].hiddenS
                                }, null, 8, _hoisted_22),
                                _createElementVNode("input", {
                                    type: "hidden",
                                    name: "l",
                                    value: _ctx.headerTranslation[_ctx.store.state.lang].hiddenL
                                }, null, 8, _hoisted_23),
                                _createVNode(_component_ion_input, {
                                    id: "search-input",
                                    type: "search",
                                    name: "q",
                                    class: "search__form-input-compact fontDbT5",
                                    autocomplete: "off",
                                    value: "",
                                    placeholder: _ctx.headerTranslation[_ctx.store.state.lang].suchen
                                }, null, 8, ["placeholder"]),
                                _hoisted_24
                            ], 8, _hoisted_21)
                        ])
                    ])
                ]),
                _: 1
            }))
            : _createCommentVNode("", true),
        (_ctx.$windowWidth > 990)
            ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", {
                        id: "OneID",
                        onMouseover: _cache[3] || (_cache[3] = ($event) => (_ctx.setMenuContent(1))),
                        class: "navDesHeadEl headingFont"
                    }, _toDisplayString(_ctx.headerDataSetup.children[0].title), 33),
                    _createElementVNode("div", {
                        id: "TwoID",
                        onMouseover: _cache[4] || (_cache[4] = ($event) => (_ctx.setMenuContent(2))),
                        class: "navDesHeadEl headingFont"
                    }, _toDisplayString(_ctx.headerDataSetup.children[1].title), 33),
                    _createElementVNode("div", {
                        id: "ThreeID",
                        onMouseover: _cache[5] || (_cache[5] = ($event) => (_ctx.setMenuContent(3))),
                        class: "navDesHeadEl headingFont"
                    }, _toDisplayString(_ctx.headerDataSetup.children[2].title), 33)
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.headingContent),
                    id: "navDesCon",
                    onMouseleave: _cache[10] || (_cache[10] = ($event) => (_ctx.setMenuContentOff()))
                }, [
                    _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("div", {
                            style: { "margin-left": "4px" },
                            onMouseover: _cache[6] || (_cache[6] = ($event) => (_ctx.setMenuContent(1))),
                            class: _normalizeClass(_ctx.headingElementOne)
                        }, [
                            _createElementVNode("a", {
                                href: _ctx.headerDataSetup.children[0].link
                            }, _toDisplayString(_ctx.headerDataSetup.children[0].title), 9, _hoisted_28)
                        ], 34),
                        _createElementVNode("div", {
                            onMouseover: _cache[7] || (_cache[7] = ($event) => (_ctx.setMenuContent(2))),
                            class: _normalizeClass(_ctx.headingElementTwo)
                        }, [
                            _createElementVNode("a", {
                                href: _ctx.headerDataSetup.children[1].link
                            }, _toDisplayString(_ctx.headerDataSetup.children[1].title), 9, _hoisted_29)
                        ], 34),
                        _createElementVNode("div", {
                            onMouseover: _cache[8] || (_cache[8] = ($event) => (_ctx.setMenuContent(3))),
                            class: _normalizeClass(_ctx.headingElementThree)
                        }, [
                            _createElementVNode("a", {
                                href: _ctx.headerDataSetup.children[2].link
                            }, _toDisplayString(_ctx.headerDataSetup.children[2].title), 9, _hoisted_30)
                        ], 34),
                        _createElementVNode("div", {
                            onMouseenter: _cache[9] || (_cache[9] = ($event) => (_ctx.setMenuContentOff())),
                            class: "navDesHeadElEmpty",
                            style: { "color": "#ec0016" }
                        }, " . ", 32)
                    ]),
                    false
                        ? (_openBlock(), _createElementBlock("div", _hoisted_31, "valueData: " + _toDisplayString(_ctx.valueData), 1))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_32, [
                        (_ctx.headerDataSetup.clusterMenu)
                            ? (_openBlock(), _createElementBlock("ul", _hoisted_33, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headingData1.children, (p, i) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                        class: "flex_25",
                                        key: i
                                    }, [
                                        _createElementVNode("a", {
                                            class: "headingTop fontDbT3Fett",
                                            rel: "nofollow",
                                            href: p.link
                                        }, _toDisplayString(p.title), 9, _hoisted_34),
                                        _createElementVNode("ul", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headingData1.children[i].children, (p_1, i_1) => {
                                                return (_openBlock(), _createElementBlock("li", { key: i_1 }, [
                                                    _createElementVNode("a", {
                                                        class: "nav__link headingTop fontDbT4",
                                                        rel: "nofollow",
                                                        href: p_1.link
                                                    }, _toDisplayString(p_1.title), 9, _hoisted_35)
                                                ]));
                                            }), 128)),
                                            _createElementVNode("li", null, [
                                                _createElementVNode("a", {
                                                    class: "nav__link headingTop fontDbT4",
                                                    rel: "nofollow",
                                                    href: p.link
                                                }, "Alle ansehen...", 8, _hoisted_36)
                                            ])
                                        ])
                                    ]));
                                }), 128))
                            ]))
                            : _createCommentVNode("", true),
                        (!_ctx.headerDataSetup.clusterMenu)
                            ? (_openBlock(), _createElementBlock("ul", _hoisted_37, [
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        class: "nav__link headingTop font-db-t4-fett",
                                        href: _ctx.headingData1.link,
                                        rel: "nofollow"
                                    }, _toDisplayString(_ctx.firstLinkTranslation[_ctx.store.state.lang]) + " " + _toDisplayString(_ctx.headingData1.title), 9, _hoisted_38)
                                ]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headingData1.children, (p_1, i_1) => {
                                    return (_openBlock(), _createElementBlock("li", { key: i_1 }, [
                                        _createElementVNode("a", {
                                            class: "nav__link headingTop fontDbT4",
                                            rel: "nofollow",
                                            href: p_1.link
                                        }, _toDisplayString(p_1.title), 9, _hoisted_39)
                                    ]));
                                }), 128))
                            ]))
                            : _createCommentVNode("", true),
                        (_ctx.headerDataSetup.children[_ctx.menuIndex].tips)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_40, [
                                (_ctx.headerDataSetup.children[_ctx.menuIndex].tips[0])
                                    ? (_openBlock(), _createElementBlock("ul", _hoisted_41, [
                                        (_ctx.headerDataSetup.children[_ctx.menuIndex].tips[0])
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_42, [
                                                _createElementVNode("a", _hoisted_43, _toDisplayString(_ctx.headerDataSetup.children[_ctx.menuIndex].tips[0].title), 1)
                                            ]))
                                            : _createCommentVNode("", true),
                                        (_ctx.headerDataSetup.children[_ctx.menuIndex].tips[1])
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_44, [
                                                _createElementVNode("a", _hoisted_45, _toDisplayString(_ctx.headerDataSetup.children[_ctx.menuIndex].tips[1].title), 1)
                                            ]))
                                            : _createCommentVNode("", true)
                                    ]))
                                    : _createCommentVNode("", true)
                            ]))
                            : _createCommentVNode("", true)
                    ])
                ], 34)
            ]))
            : _createCommentVNode("", true)
    ], 64));
}
