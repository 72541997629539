import { createRouter, createWebHistory } from "@ionic/vue-router";
const routes = [
    {
        path: "",
        name: "Content",
        component: () => import("../views/Startseite.vue"),
    },
    {
        path: "/challenge",
        alias: "/chellenge",
        name: "Redirect",
        component: () => import("../template/RedirectChallenge.vue"),
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
export default router;
