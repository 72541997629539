import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-299a813a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { style: { "display": "flex", "justify-content": "space-between" } };
const _hoisted_2 = _withScopeId(() => _createElementVNode("div", null, [
    _createElementVNode("a", {
        href: "/",
        class: "menu_resp_left"
    }, [
        _createElementVNode("img", {
            alt: "Startseite",
            src: "assets/db-logo.svg"
        }),
        _createElementVNode("div", { style: { "display": "inline-block" } }, [
            _createElementVNode("img", {
                id: "logoBahnBusResp",
                src: "assets/logo_bahnbusiness.jpg"
            })
        ])
    ])
], -1));
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ion_toolbar = _resolveComponent("ion-toolbar");
    const _component_ion_menu_button = _resolveComponent("ion-menu-button");
    const _component_ion_buttons = _resolveComponent("ion-buttons");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.$windowWidth < 991)
            ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                key: 0,
                class: "max_screen",
                style: { "border-bottom": "8px solid rgb(240, 20, 20)", "background": "white" }
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _hoisted_2,
                        _createElementVNode("div", {
                            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.openFirst())),
                            class: "head__nav-menu--open"
                        }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.menuTranslation[_ctx.store.state.lang]), 1)
                        ])
                    ])
                ]),
                _: 1
            }))
            : _createCommentVNode("", true),
        false
            ? (_openBlock(), _createBlock(_component_ion_buttons, {
                key: 1,
                slot: "end"
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_ion_menu_button, { type: "button" })
                ]),
                _: 1
            }))
            : _createCommentVNode("", true)
    ], 64));
}
